
:root {
    --fc-today-bg-color: rgba(243, 209, 209, 0.25);
    --fc-highlight-color: rgba(55, 255, 0, 0.522);
    /* --fc-highlight-color: var(--secondary-color) */
}
.activity-calendar .no-availability {
    margin-top:-20px;
    margin-bottom:20px;
    color:orange;
}
.activity-calendar .fc .fc-multimonth {
    border: 0px;
}
.activity-calendar .fc h2.fc-toolbar-title {
    font-size: 1.3em;
    text-transform: capitalize;
}
.activity-calendar .fc .fc-multimonth-header-table,
.activity-calendar .fc .fc-multimonth-daygrid-table  {
    border-left:1px solid var(--fc-border-color);
    border-right:1px solid var(--fc-border-color);
}
.activity-calendar .fc .fc-multimonth-daygrid-table  {
    border-bottom: 1px solid var(--fc-border-color) !important;
}
.activity-calendar .fc-multimonth-title {
    display:none;
}
.activity-calendar .fc .fc-day.selected a.fc-daygrid-day-number,
.activity-calendar .fc .fc-day.selected a.fc-daygrid-day-number:hover {
    color: #FFFFFF
}

/* We ensure days from other months are still visible */
.activity-calendar .fc-day.selected .fc-daygrid-day-top {
    opacity: 1;
}
.activity-calendar .fc a.fc-daygrid-day-number:hover {
    color:var(--themecolor);
}

/* .availability-calendar .fc-day.selected .fc-daygrid-bg-harness, */
.activity-calendar .fc-day.selected .fc-daygrid-bg-harness .fc-event {
    background: var(--secondary-color) !important; 
    opacity: 1;
}
.activity-calendar .fc .fc-highlight {
    background: transparent !important;
}
.activity-calendar .day-slots .button.button-border:hover {
    background-color: var(--themecolor);
    color: #FFFFFF;
}
.activity-calendar .day-slots {
    display:flex;
    justify-content: center;
    flex-wrap: wrap;
}
.activity-calendar .day-slots .button.selected {
    border-color: transparent !important;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.2);
    background-color: var(--themecolor);
    color: #FFFFFF;
}
.activity-calendar .persons .quantity {
    width:100%;
    margin-left:auto;
    margin-right:auto;
    margin-top: 10px;
    margin-bottom: 10px;
}
.activity-calendar .persons button.product-add {
    display: block;
    margin-left:auto;
    margin-right:auto;
}



.sweetness .sweet-scale {
    position:relative;
    height:12px;
}
.sweetness .sweet-scale > i {
    width:2px;
    height: 12px;
    background:#555;
    position:absolute;
}
.sweetness .legend {
    position:relative;
    height:22px;
}
.sweetness .legend > span {
    position:absolute;
    text-transform: uppercase;
}
.sweetness .legend > span > span {
    position:relative;
    left:-50%;
}
.sweetness {
    position:relative;
    width: 450px;
    height: 80px;
}
.sweetness .legend > span:nth-child(1),
.sweetness .sweet-scale > i:nth-child(1) {
    left:0px;
}
.sweetness .legend > span:nth-child(2),
.sweetness .sweet-scale > i:nth-child(2) {
    left:149px;
}
.sweetness .legend > span:nth-child(3),
.sweetness .sweet-scale > i:nth-child(3) {
    left:296px;
}
.sweetness .legend > span:nth-child(4),
.sweetness .sweet-scale > i:nth-child(4) {
    left:450px;
}
.sweetness .bar {
    width:100%;
    height:2px;
    background:#555;
    position:relative;
}
.sweetness .bar > i {
    height:15px;
    width:15px;
    border-radius: 15px;
    top:-7px;
    background: var(--secondary-color);
    position:absolute;
    left: calc(var(--wine-sweetness) - 7px);
}
.sweetness .bar > i:after { 
   content:"";
   display:block;
   width:30px;
   height:30px;
   background:url(/1706ad94-bb8f-4eb8-9c04-a5ccae63cbab//img/vigneron-online/wine-glasses-icon.png) no-repeat;
   background-size:30px 30px;
   position:relative;
   top: 20px;
   left:-5px;
}


/* Vertical sweetness */
@media (max-width: 991.98px) {
.sweetness {
        height: 200px
}
.sweetness .legend {
        margin-top: 10px;
        height: 190px;
}
.sweetness .legend > span {
        left: 100px!important;
}
.sweetness .legend > span:nth-child(2),
    .sweetness .sweet-scale > i:nth-child(2) {
        top: 50px;
}
.sweetness .legend > span:nth-child(3),
    .sweetness .sweet-scale > i:nth-child(3) {
        top: 100px;
}
.sweetness .legend > span:nth-child(4),
    .sweetness .sweet-scale > i:nth-child(4) {
        top: 150px;
        right: auto;
}
.sweetness .legend > span > span {
        left:0px
}
.sweetness .sweet-scale {
        height: 200px;
        position: absolute;
        top: 20px;
        left: 0px;
}
.sweetness .sweet-scale > i {
        width: 20px;
        height: 2px;
        left: 50px!important;
}
.sweetness .bar {
        height: 150px;
        width: 2px;
        position: absolute;
        top: 20px;
        left: 50px;
}
.sweetness .bar > i {
        left:-7px;
        top: calc(var(--wine-sweetness) - 7px);
}
.sweetness .bar > i:after { 
        top: -5px;
        left:-35px;
}
}
 



.product-details-list > h3 {
    margin:0 0 10px 0;
    text-transform: uppercase;
    font-size:18px;
}
.product-details-list > h3 > img {
    height:25px;
    margin-right:5px;
}
.product-details-list > ul {
    margin-left: 30px;
}
.product-details-list li {
    margin: 0 0 5px 0;
}
.product-details-list li span.label {
    flex-shrink: 0;
}
.product-details-list li span.content {
    margin-left:5px;
}
@media (max-width: 479px) {
.product-details-list li {
         flex-wrap: wrap;
}
.product-details-list li span.content {
        margin-left:20px;
        flex-basis: 100%;
}
}
.wine-tasting .sweetness {
    margin-top:15px;
}
.product-details .si-share span {
    float:left;
    margin-right: 10px;
}
.product-details .si-share div {
    float:left;
}
.product-details .si-share .social-icon {
    border-radius: 3px;
    margin: 0 5px 0 0
}




/* We set the size of the picture */
.product-details .product-image {
    height:auto;
    width: 95%;
}
@media (max-width: 767.98px) {
    /* We ensure that picture is taking the full width when description wrap */
.product-details .product-image {
        margin:0;
        width: 100%;
}
}

/* We set the size of the picture */
.product-details .product-image img {
    width: auto;
    margin-left:auto;
    margin-right:auto;
    max-height: 800px;
}

/* We ensure that thumbnails fit in the space */
.product-details .product-image .product-images img {
    max-height: 100%;
}


/* We set specific size for capacity and degree */
.single-product .product-capacity,
.single-product .product-degree {
  font-size: 24px;
}
.single-product .flags {
    position:absolute;
    top:-15px;
    right:0px;
}
.single-product .flags img {
    height:80px;
}
.product-details h2 {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    font-family: inherit;
}
.product-images {
    margin-top:5px;
}
.product-image > div {
    padding:0px;
}
.product-images .swiper-slide {
    height: 80px!important;
    width: 80px;
    padding-top: 0px;
    padding-bottom: 0px;
    flex: 0 0 auto;
    text-align: center;
    margin-right: 5px;
}
.product-images img {
    width: auto;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    max-height: 100%;
}
@media (min-width: 1200px) {
    /* We make sure that thumbnails are vertical */
.product-images .swiper-wrapper {
        flex-direction: column
}
    /* We make sure that the thumbnails are on the left */
.product-images {
        order: -1;
        margin-top:0px;
        margin-right:10px;
        padding-left:0px;
}
.product-images .swiper-slide {
        margin-right: 0px;
        margin-bottom:5px;
}
}
@media (min-width: 1400px) {
.product-images .swiper-slide {
        height: 100px!important;
        width: 100px;
}
}



.related-products .product {
    display:flex;
    flex-direction: column;
}
.related-products .product .product-image {
    height: 250px!important;
    width: 200px;
    padding-top: 5px;
    padding-bottom: 5px;
    flex: 0 0 auto;
    margin-left:auto;
    margin-right:auto;
}
.related-products .product .product-image > a {
    height: 100%;
}
.related-products .product .product-image img {
    width: auto;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
}
.related-products .swiper-container {
    padding-left:52px;
    padding-right:52px;
}
.related-products .swiper-slide {
    width: auto;
    display:flex;
    height:auto;
}
.related-products .product .product-desc {
    display:flex;
    flex:1 0 auto;
    flex-direction: column;
}
.related-products .product .price-and-capacity {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex: 1 0 auto;
}
.related-products .product .price-and-capacity .product-price {
    margin:0px;
}



.sticky-cart {
    position:-webkit-sticky;
    position:sticky;
    bottom:0px;
    border-top: 2px solid var(--secondary-color);
    background-color: var(--content-background-color);
    z-index: 50;
}
.sticky-cart.opened .summary {
    padding-bottom: 10px;
}
.sticky-cart .summary .container-md {
    display:flex;
    flex-wrap: wrap;
}
.sticky-cart .summary .title, .sticky-cart .summary .total {
    text-transform: uppercase;
    line-height: 34px;
    font-weight: 600;
}
.sticky-cart .summary .title a.details i:first-child {
    margin-right:10px;
}
.sticky-cart .summary .title a.details i:last-child {
    margin-left:10px;
    transition: 0.3s ease all;
}
.sticky-cart.opened .summary .title a.details i:last-child {
    transform: rotate(180deg);
}
.sticky-cart .summary .total {
    flex-grow:1;
    text-align: center;
}
@media (max-width: 575.98px) {
.sticky-cart .summary {
        padding-bottom: 10px;
}
.sticky-cart .summary .title {
        flex-grow: 1;
}
.sticky-cart .summary .total {
        flex-grow: 0;
}
.sticky-cart .summary .button {
        width:100%;
}
}
.sticky-cart .details .empty {
    padding:10px;
    text-align: center;
}
.sticky-cart .details .table th {
    border-top:0px;
}




/* Specific position for back to shop link */
.shop-product p.back-to-shop:last-child {
  margin-left:0px;
}

/* Specific position for back to shop link in case of square logo */
@media (max-width: 575.98px) {
.layout:has(#header.square-logo) .shop-product p.back-to-shop {
    margin-left:120px;
}
}

/* Specific position for back to shop link in case of square logo */
@media (min-width: 576px) {
.layout:has(#header.square-logo) .shop-product p.back-to-shop {
    margin-left:140px;
}
}

/* Specific position for back to shop link in case of square logo */
@media (min-width: 1200px) {
.layout:has(#header.square-logo) .shop-product p.back-to-shop {
    margin-left:100px;
}
}



/* We add top margin between the content and the back to shop link */
.shop-product .container > div {
  margin-top: 20px;
}
.shop-product .container > div > .product-title {
  position:relative;
  margin-bottom:30px;
  padding: 0 0 10px;
  border-bottom: 2px solid var(--secondary-color);
  display:flex;
  flex-wrap: wrap;
  align-items: flex-end;
}

/* We remove the margin of product title */
.shop-product .container > div > .product-title h1 {
  margin:0px;
}
@media (max-width: 767.98px) {
.shop-product .container > div > .product-title h2 {
    font-size: 20px;
}
}
@media (max-width: 399.98px) {
.shop-product .container > div > .product-title h1 {
    font-size: 20px;
}
.shop-product .container > div > .product-title h2 {
    font-size: 16px;
}
}



